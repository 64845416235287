import React from 'react'
import Layout from '../components/layout'
import PageTitle from '../components/pageTitle'
import ContactForm from '../components/contactForm'
import Seo from '../components/seo'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default function Request({ location }) {
  return (
    <Layout>
      <Seo title="Request Info" />
      {location.state?.product ?
        <div>
          <PageTitle
            title="Request Information"
            subtitle="Fill in your contact information below and our tool specialists will respond as soon as possible."
          />
          <div className="grid md:grid-cols-2 sm:grid-cols-1 px-4 md:px-16 py-6">
            <GatsbyImage
              image={getImage(location.state.product?.images?.sort((a,b) => a.name > b.name ? 1:-1)[0].localFile)}
              className="mb-8 md:mb-0 w-4/5 max-h-[500px] border border-secondary mx-auto" 
              alt={location.state.product.searchTitle}
              objectFit='contain'
            />
            <ContactForm title={location.state.product.model + " " + location.state.product.searchTitle} model={location.state.product.model}/>
          </div>
        </div>
        :
        <div>
          <PageTitle
            title="No product selected"
          />
        </div>}
    </Layout>
  )
}
